<!-- 我的下载 -->
<template>
  <div class="evaluating">
    <!-- <p class="myclass-title">
      <span class="title-text">我的下载</span>
      <span class="title-border"></span>
    </p> -->
    <headers ref="headers" :headname="headName"></headers>

    <!-- 有数据展示 -->
    <div v-show="count!==0" class="down_list">
      <div v-for="(item,index) in list" :key="index" class="item_down">
        <p class="top" @click="threadDetail(item)">{{ item.title }}</p>
        <p class="bot">下载时间：{{ item.create_time | formatDate }}</p>
      </div>

      <!-- 分页 -->
      <div class="feny">
        <el-pagination
          v-show="count>10"
          class="page"
          background
          layout="prev, pager, next"
          :page-size="params.limit"
          :total="count"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>

    </div>

    <!-- 无数据展示 -->
    <noData v-show="count===0" ref="noData" :msg="msg"></noData>
    <!-- <div v-show="count===0" class="noDataShow">
      <img src="@/assets/image/kong.png" alt="">
      <p>您目前还没有下载记录~</p>
    </div> -->

  </div>
</template>

<script>
import { mydown } from '@/api/center.js'
import tool from '@/utils/tools.js'
import headers from '@/components/topTitle/index.vue'
import noData from '@/components/noDataBox/index.vue'
export default {
  name: '',
  components: { headers, noData },
  filters: {
    formatDate(time) {
      if (time !== null && time !== '') {
        time = time * 1000
        const date = new Date(time)
        return tool.formatDate(date, 'yyyy-MM-dd')
      } else {
        return ''
      }
    }
  },

  data() {
    return {
      headName: '我的下载',
      msg: '您目前还没有下载记录~',
      count: undefined,
      url: '', // 帖子详情链接
      list: [],
      params: {
        page: 1,
        limit: 10
      }
    }
  },

  computed: {},

  created() {
    this.getlist()
  },

  mounted() {},

  methods: {
    getlist() {
      mydown(this.params).then(res => {
        this.list = res.result.list
        this.count = res.result.count
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    threadDetail(item) {
      window.open(item.url, '_blank')
    },
    // 分页
    handleCurrentChange(val) {
      // 当前点击页数
      this.params.page = val
      this.getlist()
    }
  }
}
</script>

<style lang="scss" scoped>
.evaluating{
	width: 965px;
  padding: 0 0 20px 0;
	min-height: 900px;
  position: relative;
  font-family: 'PingFangSC-Regular';
}
.myclass-title {
	padding: 26px 60px 12px 10px;
	width: 830px;
	font-size: 19px;
	position: relative;
	text-align: left;
}
.title-border{
	position: absolute;
	left: 0;
	bottom: 16px;
	width: 4px;
	height: 20px;
	z-index: 2;
	background: #f04e4f;
}
.down_list{
  margin-left: 30px;
  padding: 0 30px 0 0;
	.item_down{
		height: 85px;
		border-bottom: 1px solid #DDDDDD;
		text-align: left;
		padding: 20px 0;
		.top{
			font-size: 18px;
			color: #333;
			cursor: pointer;
		}
		.bot{
			color: #c5c5c5;
			margin-top: 10px;
		}
	}
}

.noDataShow{
	position: absolute;
	width: 180px;
	height: 180px;
	top: 50%;
	left: 50%;
	margin-top: -90px;
}
.noDataShow img{
	display: block;
	margin: auto;
}
.noDataShow p{
	text-align: center;
	font-size: 14px;
	color: #999999;
	margin-top: 30px;
}

.feny{
	display: inline-block;
  margin-top: 20px;
}

</style>
